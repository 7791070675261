/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Anish's Portfolio",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  og: {
    title: "Anish Mathakiya Portfolio",
    type: "website",
    url: "https://anishmathakiya.in/",
  },
};

//Home Page
const greeting = {
  title: "Anish Mathakiya",
  logo_name: "Anishmathakiya",
  //nickname: "layman_brother",
  subTitle:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  resumeLink:
    "https://drive.google.com/file/d/1k4QCkm4QBEchQFW75AgOWffc5O6FdB1h/view",
  portfolio_repository: "https://github.com/iam-anish",
  githubProfile: "https://github.com/iam-anish",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/iam-anish",
  // linkedin: "https://www.linkedin.com/in/anishmathakiya/",
  // gmail: "anishmathakiya073@gmail.com",
  // facebook: "https://www.facebook.com/anish.mathakiya.35/",
  // twitter: "https://twitter.com/anish_mathakiya",
  // instagram: "https://www.instagram.com/anish_mathakiya_073/"

  {
    name: "Github",
    link: "https://github.com/iam-anish",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/anishmathakiya/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Gmail",
    link: "mailto:anishmathakiya073@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "X-Twitter",
    link: "https://twitter.com/anish_mathakiya",
    fontAwesomeIcon: "fa-twitter", // Reference https://fontawesome.com/icons/x-twitter?f=brands&s=solid
    backgroundColor: "#000000", // Reference https://simpleicons.org/?q=x
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/anish.mathakiya.35/",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/anish_mathakiya_073/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "Backend Development (Expertise)",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Highly skilled in utilizing Spring Boot and PostgreSQL to design, develop, and optimize backend systems, ensuring robustness, efficiency, and seamless data management.",
        "⚡ Developing web applications like E-Commerce, Management System etc. As per client recruitment."
      ],
      softwareSkills: [
        {
          skillName: "Spring",
          fontAwesomeClassname: "simple-icons:spring",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "Spring Boot",
          fontAwesomeClassname: "simple-icons:springboot",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "Spring Security",
          fontAwesomeClassname: "simple-icons:springsecurity",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Postman",
          fontAwesomeClassname: "simple-icons:postman",
          style: {
            color: "#CC6699",
          },
        },
      ],
    },
    {
      title: "Frontend Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website front end using ReactJs",
        "⚡ Proficient in ReactJs, adept at crafting interactive and user-centric frontend interfaces that prioritize user experience, responsiveness, and visual appeal."
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Yarn",
          fontAwesomeClassname: "simple-icons:yarn",
          style: {
            color: "#2C8EBB",
          },
        },
      ],
    },
    {
      title: "Cloud Development",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experienced in AWS cloud infrastructure, specializing in designing, deploying, and managing scalable, secure, and reliable cloud solutions tailored to application requirements.",
        "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases"
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#4285F4",
          },
        },
      ],
    }
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://leetcode.com/anish_mathakiya/",
    },
    {
      siteName: "GeeksForGeeks",
      iconifyClassname: "simple-icons:geeksforgeeks",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://auth.geeksforgeeks.org/user/anish_mathakiya",
    }
  ]
};

const degrees = {
  degrees: [
    {
      title: "Charotar University of Science and Technology, Anand, India",
      subtitle: "B.Tech. in Computer Engineering",
      logo_path: "https://play-lh.googleusercontent.com/3_H1GEEyCiDcPf23k0qKTzFOWSTkPk4a6MRSFuWnS825TOojFrdaoolD_FCV3NF64Q",
      alt_name: "Charusat University",
      duration: "July 2021 - Ongoing",
      descriptions: [
        "⚡ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS, CA, AI etc.",
        "⚡ Apart from this, I have done courses on Sprin Boot, ReactJs and Cloud Computing"
      ],
      website_link: "https://www.charusat.ac.in/",
    },
    {
      title: "The Modern Secondary and Higher Secondary School, Wankaner",
      subtitle: "Class XII",
      logo_path: "https://png.pngtree.com/png-clipart/20211017/original/pngtree-school-logo-png-image_6851480.png",
      alt_name: "The Modern School",
      duration: "June, 2021",
      descriptions: [
        "",
      ],
    },
    {
      title: "The Modern Secondary and Higher Secondary School, Wankaner",
      subtitle: "Class X",
      logo_path: "https://png.pngtree.com/png-clipart/20211017/original/pngtree-school-logo-png-image_6851480.png",
      alt_name: "The Modern School",
      duration: "June, 2019",
      descriptions: [
        "",
      ],
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Spring Boot",
      subtitle: "- Learn Code With Durgesh",
      logo_path: "spring-boot-logo.png",
      certificate_link:
        "https://drive.google.com/file/d/1RTk87csTOC5HWahJ27i2DCwDk_FQzU68/view?usp=drive_link",
      alt_name: "Learn Code With Durgesh",
      color_code: "#00000090",
    },
    {
      title: "ReactJs",
      subtitle: "- Learn Code With Durgesh",
      logo_path: "reactjs.png",
      certificate_link:
        "https://drive.google.com/file/d/15Be4eDNOGh49U6dipsuCjDFjCJEvfWuT/view?usp=drive_link",
      alt_name: "Learn Code With Durgesh",
      color_code: "#00000099",
    },
    {
      title: "Database Management System",
      subtitle: "- NPTEL",
      logo_path: "database.jpg",
      certificate_link:
        "https://drive.google.com/file/d/1KPtwppvScpQdjeE2qRFkIpW9pIAVb7ZM/view?usp=drive_link",
      alt_name: "NPTEL",
      color_code: "#FFFFFF",
    },
    {
      title: "Git and GitHub",
      subtitle: "- Charusat",
      logo_path: "GitGithub.png",
      certificate_link:
        "https://drive.google.com/file/d/1wj901Hsbvt12lbWXqxDdz-q6xO-RGEMV/view?usp=sharing",
      alt_name: "Charusat",
      color_code: "#1F70C199",
    }
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I have collaborated with various innovative startups, primarily serving roles as a Software Engineer, Data Analyst, and Project Manager. My professional journey also led me to engage with established tech firms, predominantly in roles focused on software development and system optimization. Beyond my core responsibilities, I possess a passion for community engagement, evidenced by my active participation in volunteer roles with open-source communities and tech-driven initiatives.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Internships",
      work: true,
      experiences: [
        {
          title: "Software Development Intern",
          company: "Team Elementals",
         // company_url: "https://legatohealthtech.com/",
          logo_path: "teamelementals.jpeg",
          duration: "May - 2023 - Ongoing",
          location: "Anand, Gujarat",
          description:
            "During my Software Development Internship at Team Elementals, I delved deep into backend development, primarily leveraging Spring Boot to create robust solutions. My hands-on experience with PostgreSQL enriched my understanding of optimizing database functionalities for enhanced performance. Moreover, this internship provided invaluable insights into how startups and industries operate, allowing me to grasp the intricacies and dynamics of the business landscape. Through these experiences, I honed my skills, ensuring efficiency, seamless data flow, and a comprehensive understanding of the industry's workings.",
          color: "#0879bf",
        },
        {
          title: "Open Source Contributor",
          company: "Hacktoberfest",
          company_url: "https://hacktoberfest.com/",
          logo_path: "hacktoberfest1.png",
          duration: "October 2022",
          location: "Pune, Maharashtra",
          description:
            "During my participation in Hacktoberfest, I actively contributed as an open-source contributor, gaining valuable experience in collaborative coding environments. This engagement allowed me to enhance my skills while working with a diverse community of developers. Through Hacktoberfest, I not only expanded my technical expertise but also cultivated a deeper appreciation for open-source initiatives and their impact on fostering innovation and collaboration within the tech community.",
          color: "#9b1578",
        }
      ],
    },
    // {
    //   title: "Internships",
    //   experiences: [
    //     {
    //       title: "",
    //       company: "",
    //       company_url: "",
    //       logo_path: "",
    //       duration: "",
    //       location: "",
    //       description:
    //         "",
    //       color: "#000000",
    //     },
    //     {
    //        title: "",
    //       company: "",
    //       company_url: "",
    //       logo_path: "",
    //       duration: "",
    //       location: "",
    //       description:
    //         "",
    //       color: "#ee3c26",
    //     },
    //     {
    //        title: "",
    //       company: "",
    //       company_url: "",
    //       logo_path: "",
    //       duration: "",
    //       location: "",
    //       description:
    //         "",
    //       color: "#0071C5",
    //     },
    //   ],
    // },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Core Team Member",
          company: "CLDC",
         // company_url: "",
          logo_path: "cldc.jpeg",
          duration: "January 2023 - June 2023",
          location: "Charusat University, Gujarat",
          description:
            "As a core team member of Web Development at CLDC, I focused on testing projects and mentoring junior members, guiding them through development challenges and fostering growth within the team.",
          color: "#4285F4",
        },
        {
          title: "Volunteer",
          company: "House of Innovation Club",
          //company_url: "",
          logo_path: "houseOfInnovation.jpeg",
          duration: "June 2022 - December 2022",
          location: "Charusat University, Gujarat",
          description:
            "As a volunteer at the House of Innovation, I actively participated in managing events such as Engineer's Day and various college functions, enhancing my skills in event coordination and organization while contributing to co-curricular activities.",
          color: "#D83B01",
        },
        {
          title: "Tech Coordinator",
          company: "Charusat",
         // company_url: "",
          logo_path: "charusat.jpeg",
          duration: "Nov 2021 - June 2022",
          location: "Charusat University, Gujarat",
          description:
            "As a Tech Coordinator within our department, I spearheaded the organization of various tech-related activities, including hackathons, contests, and tech events, successfully executing these initiatives to foster innovation and engagement within the community.",
          color: "#000000",
        }
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Spring Boot and ReactJS projects and deploy them to cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};


// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "profile-pic_2.png",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with Web Development, Spring Boot, ReactJs, AWS etc.",
  },
  addressSection: {
    title: "Address",
    subtitle:
      "Anand, Gujarat - 388421",
    locality: "Anand",
    country: "IN",
    region: "Gujarat",
    postalCode: "388421",
    streetAddress: "Nadiad - Petlad Rd",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://maps.app.goo.gl/UWLPxZKqM73kHyHu9",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
};
